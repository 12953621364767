import { graphql, useStaticQuery } from 'gatsby';

export const useImageQuery = () => {
  const heroImageData = useStaticQuery(graphql`
    fragment FluidImage on File {
      childImageSharp {
        fluid(maxWidth: 670, quality: 85, srcSetBreakpoints: [254, 309, 348]) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      homeHero: file(
        relativePath: { eq: "sellado-techo-residencial-danosa.jpg" }
      ) {
        ...FluidImage
      }
      commercialHero: file(
        relativePath: { eq: "sellado-techo-comercial.jpg" }
      ) {
        ...FluidImage
      }
      aboutHero: file(relativePath: { eq: "sellado-techo-about.jpg" }) {
        ...FluidImage
      }
      contactHero: file(
        relativePath: { eq: "sellado-techo-industrial-federal-4.jpg" }
      ) {
        ...FluidImage
      }
      notFoundImage: file(
        relativePath: { eq: "patrick-tomasso-QMDap1TAu0g-unsplash.jpg" }
      ) {
        ...FluidImage
      }
    }
  `);
  return heroImageData;
};
